import React from "react";
import {
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  ContentTitle,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../../components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import "./blog.css";

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      BlogImage: file(relativePath: { eq: "blogs/blog3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title={"Top winter interior trends for 2019"} />
      <ContainerContent style={{ padding: 0 }}>
        <Img
          fluid={data.BlogImage.childImageSharp.fluid}
          style={{ marginBottom: "8px", maxHeight: 500 }}
        />
      </ContainerContent>
      <ContainerContent>
        <ContainerContentFixed>
          <ContentTitle title={"Top winter interior trends for 2019"} />
          <div className={"cp-blog"}>
            <p>
              Just like the season changes, so does the trends. As we make a new
              transition when winter time hits, the temperature drops and our
              daily routine takes a turn. Cold coffee to hot coffee, air
              conditioners to heaters and what not. As a result we tend to spend
              most of our time indoors, keeping ourselves as cozy as possible
              and for that you should make sure your home has a warm atmosphere
              place to relax and chill to balance things out. Layering a room
              with just a couple of trends can transform it from ordinary to
              striking We have gathered few future predictions for big winter
              trends of 2019 that will spruce up your home’s style and make you
              urge to redecorate it right now! Read below and pick what suits
              your best interest and start decorating.
            </p>
            <h4>Feel of summers in winters</h4>
            <p>
              What’s warmer than a natural wood environment? You sitting infront
              of a fireplace covered in fluffy comforting blankets would be the
              best possible feeling when you are freezing on a chilly day.
              Natural wood is always a favorite and is predicted to make a
              powerful comeback this winter. This material is nothing like
              anything that brings a sense of warmth no other materials can! It
              can be either used for furniture, cabinetry, flooring, and much
              more. The beauty of this material stands in the fact that it is
              evergreen and provides the best touch even in a modern home.{" "}
            </p>
            <p>
              Wood in today’s modern interiors is a reinterpretation of the
              already existing concepts: statement walls, high beams, finished
              ceilings and treated floors. Thanks to its earthen glow and
              natural texture, wood organically adds sincerity in homes where
              steel and concrete became strongly-used staples. It makes a room
              grounded, enhancing contemporary spaces with an inspiring sense of
              rusticity.
            </p>
            <p>
              As opposed to dark hues, neutral tones are often chosen to create
              a quiet atmosphere that can easily be decorated with a
              monochromatic palette, or a surprising pop of colour. For a bit of
              variety, designers might use wood panels as a feature corner wall,
              providing a contrast against plain concrete, brick, or stone
              surrounds. Copper, silver tones, glass and steel also work great
              with the materials mentioned above. They can be used to highlight
              the beauty of the wood or as accessories. Regardless, it’s
              recommended to use them in limited amounts, at least during the
              winter.
            </p>
            <h4>Hottest colour in action</h4>
            <p>
              Last year was all about bringing an edgier palette into the
              home–with vibrant reds, modern metallics, and variations of the
              statement black accent wall. Unlike 2018’s colour trends, 2019 is
              taking a more mindful, lifestyle-based approach to the development
              of new shades.
            </p>
            <p>
              We are yelling yellow! Pinterest predicted that yellow would be a
              big hit this year and we couldn’t agree more, yellow is
              everywhere. From mellow pastels to rich mustards, these hues have
              graced catwalks, high streets and homes for months now and there’s
              no sign of it slowing as we go into the next season.
            </p>
            <p>
              Other options could be woodland shades keeping the natural tones
              in mind like mushroom grays and fern-inspired colours.
            </p>
            <p>
              To instantly energize and uplift the mood, you can also go for
              coral based colours or instead of using coral literally you can
              separate it into it’s pink and orange counterparts.
            </p>
            <p>
              Hunter green also is a big game this year. It holds a sultry and
              wordly value to it. It’s timeless in every respect, and works
              beautifully with natural elements and neutral tones.
            </p>
            <p>
              Deep saturated colours is another mood, perfect for custom
              built-ins. Ideally used in a room with abundance of natural light.
            </p>
            <p>
              In 2019, we’re going to see blues with a softened mistiness and
              haze. This moody blue has a calming grey undertone that promotes a
              more serene energy in the home.
            </p>
            <p>
              Pale pinks are prevailing in 2019 due to their neutral properties
              and compatibility with other shades.
            </p>
            <p>
              The minimalist movement has encouraged many modern homemakers to
              live a more clutter free, thoughtful life. With this, shades
              associated with minimalism–creams, beiges, and whites–are being
              incorporated into many home palettes.
            </p>
            <p>
              Grey is still proving popular and we’re especially infatuated with
              Crown’s new interpretation of the trend. Inspired by rustic,
              earthy tones, Grey Suede is a textured matte paint that creates
              the effect of brushed suede. Strong enough to stand-up to brighter
              shades, it makes a great backdrop for a bold graphic wall in a
              hallway or living room.
            </p>
            <p>
              Soft and fresh, neo mint is set to soar by 2020. Its pale
              botanical-inspired hue is a soothing choice for a bedroom and
              would even work well on the walls of a unisex nursery. Here, it’s
              been paired with a pale blue skirting band for a quirky two-tone
              effect.
            </p>
            <p>
              In a twist from the bright reds we saw last year, the deepest
              bodied of wines, Merlot, is the next big shade on the horizon.
              Rich and luxurious, contrast is key to making this stunning shade
              really sing. Combine with soft woods and copper accents for a cozy
              living room that really packs a punch.
            </p>
            <p>
              <b>Quick Note: </b>Another color that’s been working extremely
              well this winter is gold. You can use it as an accessory, as a
              highlight in combination with other colors or as the dominant
              color. It may be a bit too much to make gold the main color in
              your living room, but it’s a bold statement and brings in a lot of
              warmth.
            </p>
            <h4>Velvety luxury</h4>
            <p>
              Velvet-a mysterious, luxurious fabric has been back for a while
              now but we still can’t get enough of it. Many people are
              apprehensive about the use of velvet as it is a heavy,
              overwhelming fabric. However, when used correctly, velvet can add
              a touch of luxury, glamour and warmth to your living space.Tactile
              and durable with a hint of decadence, the texture will instantly
              turn a room from drab to fab. At its most vamp-like in deep tones,
              it brings a touch of gothic glamour to the home but it can be
              found in every colour imaginable to effortlessly blend with your
              interior. So this season why not try some pastel velvet
              accessories?
            </p>
            <p>
              As the main furnishing in the bedroom, the bed sets the tone for
              the entire space and contributes considerably to the style of the
              decor. The right bed can also make the master bedroom or guest
              room more luxurious and comfortable. Velvet beds offer the ideal
              mix of plush comfort and sophisticated style to complete the
              bedroom in an elegant way and make the space into a refuge from
              the world.
            </p>
            <p>
              Velvet beds are an excellent choice for anyone who sits up to read
              or watch television due to their padding and sumptuous fabric. In
              any bedroom, the plush look of velvet makes a refined statement
              that elevates the space. Velvet curtains are another interesting
              element that can instantly uplift a room. Whether you install them
              in a bedroom or living room, it will compose a polished look.
              Velvet curtains do not require complicated patterns. Its unique
              texture and color can better reflect their hidden luxury.
            </p>
            <h4>Swap minimalism for maximalism</h4>
            <p>
              While the world of interiors has been dominated by minimalist
              Scandinavian design these past few years, we’re heading toward a
              return to more maximalist tendencies—all in a fresh and modern
              way. That’s not to say everyone will revert to a more traditional
              or classic style. Instead, we predict people will be more
              thoughtful about acquiring long-lasting pieces from different
              periods and styles to create interiors that are intrinsically
              unique.
            </p>
            <p>
              Bigger is better, right? One of our favorite winter 2019 interior
              design trends is not just wallpaper, but large scale print
              wallpaper. Bring the outdoors in with botanical prints, or try
              solid backgrounds for a more subtle pop. If wallpaper is too much
              of a commitment, don’t worry!
            </p>
            <p>
              There are so many great peel and stick wallpaper options out there
              that are a great way to update your home without ever having to
              deal with the hassle of taking wallpaper down!
            </p>
            <p>
              <b>Sustainable, handmade pieces</b> has been in development for a
              couple of years now, and whilst 2018 gave it a good kick start,
              2019 will really see it take off. Sometimes the best answer for a
              quick room update is adding one item that becomes the focal point
              of the space.
            </p>
            <p>
              Break all the rules and go anti-trend, do what makes you happy.
              Experiment with different colour combinations and mixing patterns.
              Who knows you create a new trend?
            </p>
          </div>
        </ContainerContentFixed>
      </ContainerContent>
      <section>
        <ContainerCta
          title={"Your future happiness is waiting"}
          subTitle={"Start building your custom Grandeur Home"}
          primaryLink={InternalLinkTypes.GetStarted}
          primaryText={"Get Started"}
          secondaryLink={InternalLinkTypes.Blogs}
          secondaryText={"Read other blogs"}
        />
      </section>
    </Layout>
  );
};

export default Blog;
